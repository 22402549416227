<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="RMF" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense class="text-no-wrap">
                <v-col cols="12" sm="6" md="3">
                  <v-select
                    v-model="record.ctg"
                    style="width:150px"
                    :hide-details="hided"
                    :dense="dense"
                    item-text="name"
                    item-value="value"
                    :label="$t('Tip')"
                    :items="dictionary.categorii_rmf"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span class="d-none">{{ render }}</span>
                  <v-text-field
                    v-model="record.fields['200'][0].a[0].val"
                    v-mask="rmfMask()"
                    :rules="validateValue('200', 'a')"
                    style="width:150px"
                    autofocus
                    :hide-details="hided"
                    :dense="dense"
                    :label="'200a ' + $t('RMF')"
                    @input="render++"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <s-date-time
                    v-model="record.fields['220'][0].a[0].val"
                    :rules="validateValue('220', 'a')"
                    style="width:125px"
                    :hide-details="hided ? 'true' : 'false'"
                    :dense="dense"
                    :label="'220a ' + $t('Data RMF')"
                    type="date"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <s-date-time
                    v-model="record.fields['310'][0].a[0].val"
                    :rules="validateValue('310', 'a')"
                    style="width:125px"
                    :hide-details="hided ? 'true' : 'false'"
                    :dense="dense"
                    :label="'310a ' + $t('Data transfer')"
                    type="date"
                  />
                </v-col>
              </v-row>
              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                class="mt-3"
                color="primary"
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Descriere") }}</v-tab>
                <v-tab>{{ $t("Document") }}</v-tab>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['330'][0].a[0].val"
                        :rules="validateValue('330', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'330a ' + $t('Provieniență')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['300'][0].a[0].val"
                        :rules="validateValue('300', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        rows="3"
                        :label="'300a ' + $t('Note')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="3">
                      <v-select
                        v-model="record.fields['410'][0].t[0].val"
                        :rules="validateValue('410', 't')"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'410t ' + $t('Tip document')"
                        :items="dictionary.RmfTipDoc"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        v-model="record.fields['410'][0].b[0].val"
                        :rules="validateValue('410', 'b')"
                        class=""
                        :hide-details="hided"
                        :dense="dense"
                        :label="'410b ' + $t('Prefix')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        v-model="record.fields['410'][0].c[0].val"
                        :rules="validateValue('410', 'c')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'410c ' + $t('Nr. document')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        v-model="record.fields['410'][0].d[0].val"
                        :rules="validateValue('410', 'd')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'410d ' + $t('Sufix')"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <s-date-time
                        v-model="record.fields['410'][0].e[0].val"
                        :rules="validateValue('410', 'e')"
                        style="width:125px"
                        :hide-details="hided ? 'true' : 'false'"
                        :dense="dense"
                        :label="'410e ' + $t('Data document')"
                        type="date"
                      />
                    </v-col>
                    <v-col cols="12" sm="8" md="9">
                      <span class="d-none">{{ render }}</span>
                      <s-autocomplete
                        :value="record.fields['410'][0].a[0].val"
                        :label="'410a ' + $t('Furnizor')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        return-object
                        view-type="marc"
                        item-text="fields.200.a.val"
                        item-value="fields.200.a.val"
                        collection="ebib_marc_prv"
                        @search="searchRelationMarc('410', 'a', 0, 'prv', null)"
                        @input="setLink('410', 'a', 0, 'prv', null, $event)"
                      >
                        <template v-slot:append>
                          <v-icon
                            class="pt-1"
                            tabindex="-1"
                            @click.stop.prevent="searchRelationMarc('410', 'a', 0, 'prv', null)"
                          >
                            mdi-magnify
                          </v-icon>
                        </template>
                      </s-autocomplete>
                    </v-col>
                    <v-col cols="6" sm="6" md="3">
                      <v-text-field
                        v-model="record.fields['410'][0].i[0].val"
                        :rules="validateValue('410', 'i')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'410i ' + $t('Preț fără TVA')"
                      />
                    </v-col>
                    <v-col cols="6" sm="6" md="3">
                      <v-text-field
                        v-model="record.fields['410'][0].j[0].val"
                        :rules="validateValue('410', 'j')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'410j ' + $t('TVA(%)')"
                      />
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        v-model="record.fields['410'][0].k[0].val"
                        :rules="validateValue('410', 'k')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'410k ' + $t('Preț total')"
                      />
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-select
                        v-model="record.fields['410'][0].l[0].val"
                        :rules="validateValue('410', 'l')"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'410l ' + $t('Moneda')"
                        :items="dictionary.moneda"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['410'][0].x[0].val"
                        :rules="validateValue('410', 'x')"
                        :hide-details="hided"
                        :dense="dense"
                        rows="3"
                        :label="'410x ' + $t('Note')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </base-material-tabs>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
import SDateTime from "../../../components/inputs/DateTime"
import SAutocomplete from "../../../components/inputs/Autocomplete"

import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    SDateTime,
    SAutocomplete
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          200: [{ ord: "", a: [{ ord: "", val: "" }], p: [{ ord: "", val: "" }], s: [{ ord: "", val: "" }] }],
          211: [{ ord: "", t: [{ ord: "", val: "" }] }],
          220: [{ ord: "", a: [{ ord: "", val: "" }] }],
          230: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }], t: [{ ord: "", val: "" }] }],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          310: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          410: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }]
            }
          ],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          856: [
            {
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              q: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          901: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          200: [{ ord: "", a: [{ ord: "", val: "" }], p: [{ ord: "", val: "" }], s: [{ ord: "", val: "" }] }],
          211: [{ ord: "", t: [{ ord: "", val: "" }] }],
          220: [{ ord: "", a: [{ ord: "", val: "" }] }],
          230: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }], t: [{ ord: "", val: "" }] }],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          310: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          410: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }]
            }
          ],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          856: [
            {
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              q: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          901: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: ["all.categorii_rmf", "all.RmfTipDoc", "all.moneda"]
      },
      rows: []
    }
  },

  computed: {
    rmfMask() {
      const year = [/[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/]
      const nr = [/[0-9]/, /[0-9]/, /[0-9]/]
      if (this.record.fields["200"][0].a[0].val.length > 7) {
        return [...year, "/", ...nr, "/", /(.*)/, /(.*)/, /(.*)/, /(.*)/, /(.*)/, /(.*)/]
      }
      return [...year, "/", ...nr]
    },
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    getInv() {
      axios.get('ebib_loan_active?aggregate={"$where":{},"$sort":{}}&max_results=10').then(response => {
        this.$log("resinvtm ", response)
      })
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      this.$log("eeee ", e)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
